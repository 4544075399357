<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-list-item
      two-line
      class="my-0"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <v-list-item-icon class="mr-3">
        <v-icon
          v-if="!menu && (!hover || !notification.seen)"
          :size="28"
          :color="seriousnessColor(notification.activity.seriousness)"
          v-text="iconByType(notification.activity.type)"
        />

        <v-menu
          v-else
          v-model="menu"
          bottom
          left
          offset-y
          origin="top right"
          transition="scale-transition"
          content-class="mt-2"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              small
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-dots-horizontal
              </v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item @click="markNotificationAsNotSeen">
              <v-list-item-title>
                {{ $t('messages.views.admin.components.core.notification.notification.markNotificationAsNotSeen') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-icon>
      <v-list-item-content class="pb-0 my-0">
        <div
          :class="notification.seen ? 'primary--text' : 'secondary--text font-weight-bold'"
        >
          {{ notification.activity.title }}
        </div>
        <div class="text-caption">
          {{ notification.activity.description }}
        </div>
        <div class="font-italic text-caption mt-1 text-right">
          {{ moment.utc(notification.createdAt).local().format('L LTS') }}
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-hover>
</template>

<script>
  import { mapState } from 'vuex';
  import { seriousnessColor } from '@utils/defect';
  import { iconByType } from '@/utils/notification';

  export default {
    props: {
      notification: {
        type: Object,
        required: true,
      },
    },

    data: () => ({
      menu: false,
    }),

    computed: {
      ...mapState('auth', ['user']),
    },

    methods: {
      seriousnessColor,
      iconByType,
      markNotificationAsNotSeen () {
        this.$apollo.mutate({
          mutation: require('@gql/mutations/notification/markNotificationAsNotSeen.gql'),
          client: 'activity-thread',
          variables: {
            input: {
              id: this.notification.id,
            },
          },
        });
        this.$emit('unseen');
      },
    },
  };
</script>
