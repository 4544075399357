import i18n from '@/plugins/i18n';

export const DEFECT_TYPE_SERIOUSNESS = {
  0: 2,
  1: 1,
  2: 1,
  3: 2,
  4: 1,
  5: 1,
  6: 2,
  7: 2,
  8: 2,
  9: 2,
  10: 2,
  11: 2,
  12: 2,
  13: 2,
  14: 2,
  15: 2,
  16: 2,
  17: 1,
  20: 2,
  21: 1,
  22: 1,
  23: 1,
  24: 1,
  25: 1,
  26: 1,
  27: 1,
  30: 0,
  31: 0,
  32: 0,
  33: 0,
};

export const getSeriousness = function (type) {
  for (const seriousness of [2, 1, 0]) {
    if (getTypes(seriousness).includes(type.toString())) {
      return seriousness;
    }
  }

  return null;
};

export const getDefectDescription = function (type) {
  if (type >= 0 && type <= 33) {
    return i18n.t('constants.defect.description.' + type);
  } else {
    return i18n.t('constants.defect.description.unknown');
  }
};

export const getDefectParameterType = function (defectType) {
  const parameters = {
    trafficLight: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 14, 15, 17],
    antagonism: [10],
    number: [12, 13],
    correlation: [16],
    coordination: [24],
    input: [25, 26],
    remoteIndex: [32],
    masterIPLeastSignificantByte: [33],
  };

  for (const name of Object.keys(parameters)) {
    if (parameters[name].includes(defectType)) {
      return name;
    }
  }

  return null;
};

export const getDefectParameterLabelFromType = function (defectType) {
  const parameterType = getDefectParameterType(defectType);

  if (parameterType !== null) {
    return i18n.t('constants.defect.parameter.' + parameterType);
  } else {
    return i18n.t('labels.defect.parameter');
  }
};

export const getTypes = function (seriousness) {
  if (seriousness === null) {
    return [];
  } else if (seriousness === false) {
    return Object.keys(DEFECT_TYPE_SERIOUSNESS);
  }

  const types = [];
  for (const type of Object.keys(DEFECT_TYPE_SERIOUSNESS)) {
    if (DEFECT_TYPE_SERIOUSNESS[type] === seriousness) {
      types.push(type);
    }
  }

  return types;
};

export const seriousnessColor = function (seriousness) {
  return {
    2: 'error',
    1: 'warning',
    0: 'info',
  }[parseInt(seriousness)] ?? 'primary';
};
